<template>
  <b-container id="pending-filings-page">
    <masthead
      title="Filing Orders"
      @changed="searchChanged"
    >
      <span />
      <b-button
        :disabled="!selection.length > 0"
        class="rounded"
        variant="danger"
        @click="showCancelModal()"
      >
        Cancel Selected
      </b-button>
    </masthead>
    <ajax-table
      ref="filingsTable"
      :busy.sync="isBusy"
      :table-definition="tableDefinition"
      select-mode="multi"
      :selectable="true"
      :selectable-function="selectableFunction"
      empty-text="You're all caught up!"
      @selection-changed="selectionEvent"
      @loaded="onLoad"
    >
      <template slot="head(status)">
        <div>
          Status
          <template v-if="hasVipFiling">
            <feather-icon
              :id="`vip-tooltip`"
              class="tooltip-icon"
              type="help-circle"
            />
            <b-tooltip
              :target="`vip-tooltip`"
              triggers="hover"
            >
              You can make changes to filings within your VIP Subscription by going to your Services page.
            </b-tooltip>
          </template>
        </div>
      </template>

      <template slot="cell(company)" slot-scope="data">
        <div>{{ data.item.company.name }}</div>
      </template>
      <template slot="cell(jurisdiction)" slot-scope="data">
        <div>{{ getFilingJurisdiction(data.item) }}</div>
      </template>
      <template slot="cell(estimate)" slot-scope="data">
        <div>{{ formatEstimate(data.item) }}</div>
      </template>
      <template slot="cell(type)" slot-scope="data">
        <div>
          <div>{{ data.item.product.name }}</div>
          <div v-if="data.item.sub_registration && data.item.sub_registration.kind === 'dba'">
            <small class="text-muted">
              {{ data.item.sub_registration.name }}
            </small>
          </div>
        </div>
        <div v-if="vipFiling(data.item)" class="vip-badge-group">
          <div class="badge badge-warning vip-badge">
            VIP
          </div>
        </div>
      </template>
      <template slot="cell(status)" slot-scope="data">
        <div
          v-if="data.item.status === 'awaiting-client-input'"
          class="awaiting-client-input-container"
          @click="navigateToORAPage()"
        >
          <feather-icon
            class="alert-icon"
            type="alert-triangle"
            :width="17"
            :height="17"
          />
          <div>{{ startCase(data.item) }}</div>
        </div>
        <div v-else>
          {{ startCase(data.item) }}
        </div>
      </template>
      <template slot="cell(actions)" slot-scope="data">
        <b-button
          :to="{
            name: 'filingStatus',
            params: { orderId: data.item.order_id, orderItemId: data.item.id },
          }"
          variant="link"
        >
          View
        </b-button>
      </template>
    </ajax-table>
    <cancel-filings-modal
      :bus="bus"
      @completed="filingsCancelCompleted"
    />
  </b-container>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'
import Vue from 'vue'

export default {
  name: 'PendingFilings',
  components: {
    Masthead:            () => import('@/components/shared/Masthead'),
    AjaxTable:           () => import('@/components/shared/AjaxTable'),
    FeatherIcon:         () => import('@/components/shared/FeatherIcon'),
    CancelFilingsModal:  () => import('@/components/CancelFilingsModal'),
  },
  data() {
    return {
      query: null,
      showModal: false,
      selection: [],
      bus: new Vue(),
      hasVipFiling: false,
      filingMethods: null,
      filingMethodIds: null,
      isBusy: false,
    }
  },
  computed: {
    tableDefinition() {
      return {
        columns: [
          { key: 'company', label: 'Company', sortable: true, sortAs: 'company.name' },
          { key: 'jurisdiction', label: 'Jurisdiction', sortable: true, sortAs: 'registration.jurisdiction.state_province_region' },
          { key: 'estimate', label: 'Estimate', sortable: true, sortAs: 'product.filing_method.docs_in' },
          { key: 'type', label: 'Type', sortable: true, sortAs: 'product.name' },
          { key: 'status', label: 'Status', sortable: true },
          // { key: 'actions', label: 'View' } TODO: reenable view button once FilingStatus page is actually implemented
        ],
        url: 'client/order_items/pending_filings',
        parameters: {
          search_name: 'search_pending_filings_page',
          query: this.query,
        },
        defaultOrderDirection: 'desc',
        defaultOrderBy: 'created_at',
      }
    },
  },
  methods: {
    ...mapActions('orderItems', ['cancelOrderItems']),
    selectionEvent(selection) {
      this.selection = selection
    },
    active(status) {
      return !['cancel-requested', 'refunded', 'completed', 'expired', 'submitted-to-state', 'in-progress', 'submitted-to-newspaper'].includes(status)
    },
    getFilingJurisdiction(item) {
      if (item.registration) {
        return item.registration.jurisdiction.state_province_region
      }
      else if (item.sub_registration) {
        return item.sub_registration.jurisdiction.state_province_region
      }
      else {
        return 'Federal'
      }
    },
    vipFiling(item) {
      if (item.subscription_id != null) {
        this.hasVipFiling = true
        return true
      }
      return false
    },
    selectableFunction(item) {
      return this.active(item.status) && !this.vipFiling(item)
    },
    async getFilingMethods(){
      const response = await this.$store.dispatch('filingMethods/fetchFilingMethods', {
        params: {
          id:  this.filingMethodIds,
        },
      })

      this.filingMethods = response.data
      this.isBusy = false
    },
    formatEstimate(item) {
      let filingMethod = null
      if (this.filingMethods) {
        filingMethod = this.filingMethods.find(method => method.id === item.product.filing_method.id)
      }

      if (filingMethod) {
        if ((filingMethod.filedIn.days === 0 || !filingMethod.filedIn.days)
          && filingMethod.filedIn.hours > 0) {
          return filingMethod.filedIn.hours + ' hours'
        } else {
          return filingMethod.filedIn.days + ' days'
        }
      } else if ((item.product.filing_method.filed_in.days === 0 || !item.product.filing_method.filed_in.days) &&
        item.product.filing_method.filed_in.hours > 0) {
        return item.product.filing_method.filed_in.hours + ' hours'
      } else {
        return item.product.filing_method.filed_in.days + ' days'
      }
    },
    searchChanged(search) {
      this.query = search.query
    },
    async showCancelModal() {
      this.bus.$emit('openCancelFilings', this.selection)
    },
    filingsCancelCompleted() {
      this.$refs.filingsTable.reload()
    },
    onLoad(tableData) {
      this.isBusy = true
      this.filingMethodIds = tableData.map(a => a.product.filing_method.id)
      this.getFilingMethods()
    },
    startCase: input => {
      const filingsWithNameChange = {
        'trademark': {
          'oa-received': 'Office Action Issued',
          'oa-woi': 'Office Action - Awaiting Client Input',
          'oa-drafting': 'Office Action - Drafting Response',
          'oa-submitted': 'Office Action - Response Submitted',
          'submitted-to-uspto': 'Submitted To USPTO',
          'published-intent-to-use': 'Mark Published',
          'published-in-use': 'Mark Published',
          'stale-2': 'Pending Client Response',
          'stale-3': 'Pending Client Response',
          'stale-4': 'Pending Client Response',
          'tm-refund-2': 'Trademark Refund',
          'tm-refund-3': 'Trademark Refund',
          'tm-refund-4': 'Trademark Refund',
          'clearance-search-woi': 'Clearance Search - Waiting on Information',
        },
        'cease and desist': {
          'new': 'Cease and Desist - New',
          'in-progress': 'Cease and Desist - In Progress',
          'processing': 'Cease & Desist - Processing',
          'completed': 'Cease & Desist - Completed',
        },
      }

      if (!Object.keys(filingsWithNameChange).includes(input.product.filing_name)) {
        return _.startCase(input.status)
      }
      return filingsWithNameChange[input.product.filing_name][input.status] || _.startCase(input.status)
    },
    async navigateToORAPage() {
      await this.$router.push({ name: 'orderItemsRequiringAttention' })
    },
  },
}
</script>

<style lang="scss">
  $standard-cell-width: 17.5 em;

  #pending-filings-page {
    .vip-badge-group {
      .vip-badge {
        color: white;
        font-size: 1.01rem;
        font-weight: 700 !important;
      }
    }

    // TODO extract to other ct-tables
    .expandable-row {
      padding: 0 !important;
      margin: 0 !important;
    }

    // Company
    [aria-colindex='2'] {
      width: $standard-cell-width;
    }

    // Type
    [aria-colindex='5'] {
      width: $standard-cell-width;

      div.expandable-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    // Status
    [aria-colindex='6'] {
      width: $standard-cell-width;

      .awaiting-client-input-container {
        display: flex;
        flex-wrap: wrap;
        color: $ct-ui-color-23;
        text-decoration: underline;

        .alert-icon {
          display: flex;
          align-items: center;
          padding-right: 0.37em;
        }
      }
      .awaiting-client-input-container:hover {
        color: transparentize($ct-ui-color-23, 0.4);
      }
    }
  }
</style>
